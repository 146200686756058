<template>
  <el-select
    :value="value"
    @change="changeValue"
    :disabled="disabled"
    filterable
    placeholder="Select"
    style="width: 100%"
  >
    <el-option v-if="!required" :key="null" label="-請選擇老師" :value="null" />
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="
        `${user.displayName(item.first_name, item.last_name)} (${item.email})`
      "
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { user } from "@ivy-way/material";
import profileApi from "@/apis/profile";

export default {
  name: "Teacher",
  props: {
    teachers: {
      type: Array,
      default: null
    },
    value: {
      type: Number,
      default: null
    },
    required: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    teachers: {
      immediate: true,
      handler(teachers) {
        this.options = teachers;
      }
    }
  },
  computed: {
    user() {
      return user;
    }
  },
  async created() {
    if (this.teachers === null) {
      const teachersRes = await profileApi.fetchTeachers();
      this.options = teachersRes.users;
    }
  },
  data() {
    return {
      options: []
    };
  },
  methods: {
    changeValue(teacherId) {
      let teacher = { id: null, name: "" };
      if (teacherId) {
        teacher = this.options.find(option => option.id === teacherId);
      }
      this.$emit("change", teacher);
    }
  }
};
</script>
