<template>
  <div>
    <el-form label-width="100px">
      <el-form-item :label="$t('grading.Students')">
        <el-select
          class="select-test"
          v-model="selectedStudents"
          multiple
          filterable
          clearable
          placeholder="Search"
          no-data-text="請輸入姓名搜尋"
          :filter-method="filterCheckPerOptions"
        >
          <el-option
            v-for="(student, index) in allStudentsTop30"
            :key="index"
            :value="student.id"
            :label="
              (student.first_name ? student.first_name : '') +
                ' ' +
                (student.last_name ? student.last_name : '') +
                ' [' +
                student.email +
                ']'
            "
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('grading.Type')">
        <el-radio-group v-model="diagnostic_type">
          <el-radio-button
            value="SAT_COMPOSE_PRACTICE"
            label="SAT_COMPOSE_PRACTICE"
            >SAT</el-radio-button
          >
          <el-radio-button value="SAT" label="SAT">SAT紙本</el-radio-button>
          <el-radio-button value="ACT" label="ACT">ACT</el-radio-button>
          <el-radio-button value="TOEFL" label="TOEFL">TOEFL</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('grading.Test')" v-if="tests.length > 0">
        <el-select
          v-model="test"
          filterable
          class="select-test"
          v-if="diagnostic_type === 'SAT_COMPOSE_PRACTICE'"
        >
          <el-option
            v-for="(item, index) in tests"
            :key="index"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select v-else v-model="test" filterable class="select-test">
          <el-option
            v-for="(item, index) in tests"
            :key="index"
            :label="item.title"
            :value="item.exam_id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('grading.Send mail')"
        class="keyboard"
        v-if="test > 0"
      >
        <el-radio-group v-model="sendEmail">
          <el-radio-button label="1">
            {{ $t("grading.Yes") }}
          </el-radio-button>
          <el-radio-button label="0">
            {{ $t("grading.No") }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        :label="$t('grading.Email Content')"
        class="keyboard"
        v-if="test > 0"
      >
        <el-radio-group
          v-model="sendContent"
          v-if="diagnostic_type === 'SAT_COMPOSE_PRACTICE'"
        >
          <el-radio-button label="3">SAT線上考試鏈接</el-radio-button>
        </el-radio-group>
        <el-radio-group
          v-model="sendContent"
          v-else-if="diagnostic_type === 'TOEFL'"
        >
          <el-radio-button label="3">TOEFL線上考試鏈接</el-radio-button>
        </el-radio-group>
        <el-radio-group v-else v-model="sendContent">
          <el-radio-button label="1"
            >{{ $t("grading.PDF") }}＋{{ $t("grading.Timer") }}＋{{
              $t("grading.Grading Link")
            }}</el-radio-button
          >
          <el-radio-button label="2"
            >{{ $t("grading.Timer") }}＋{{
              $t("grading.Grading Link")
            }}</el-radio-button
          >
          <el-radio-button label="3">{{
            $t("grading.Grading Link")
          }}</el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <div class="text-center" style="margin-bottom: 20px">
      <el-button
        type="success"
        @click="setTests"
        :disabled="!(selectedStudents.length > 0 && test > 0)"
      >
        {{ $t("greet.setTest") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import courseApi from "@/apis/course";
import userApi from "@/apis/user";
import Tests from "@/apis/tests.js";
export default {
  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      sessionsTestsSAT: [],
      sessionsTestsACT: [],
      sessionsTestsTOEFL: [],
      sessionsTestsSATPractices: [],
      sessionsTestsOnlineSATPractices: [],
      tests: [],
      test: null,
      diagnostic_type: "SAT_COMPOSE_PRACTICE",
      allStudents: [],
      allStudentsTop30: [],
      selectedStudents: [],
      sendEmail: "1",
      sendContent: "3"
    };
  },
  computed: {},
  watch: {
    diagnostic_type(val) {
      this.getTests(val);
      this.test = null;
      if (val === "SAT_COMPOSE_PRACTICE" || val === "TOEFL") {
        this.sendContent = "3";
      } else {
        this.sendContent = "1";
      }
    }
  },

  mounted() {
    this.getAssignTest();
    this.getStudents();
  },

  methods: {
    filterCheckPerOptions(query = "") {
      // query是输入框中的检索条件
      let arr = this.allStudents.filter(item => {
        if (
          item.first_name &&
          item.first_name.toLowerCase().includes(query.toLowerCase())
        ) {
          return true;
        }
        if (
          item.last_name &&
          item.last_name.toLowerCase().includes(query.toLowerCase())
        ) {
          return true;
        }
        if (
          `${item.first_name} ${item.last_name}`
            .toLowerCase()
            .includes(query.toLowerCase())
        ) {
          return true;
        }
        if (
          `${item.last_name}${item.first_name}`
            .toLowerCase()
            .includes(query.toLowerCase())
        ) {
          return true;
        }
        if (`${item.email}`.toLowerCase().includes(query.toLowerCase())) {
          return true;
        }
        return false;
      });
      console.log(arr);
      // 根据检索条件筛选出来的选项，只取前30条
      if (arr.length > 30) {
        arr = arr.slice(0, 30);
      }
      // 清空之前的选项
      this.allStudentsTop30.splice(0, this.allStudentsTop30.length);
      // chosen表示已被选择的选项，添加这一部分主要是为了回显，避免选择框中直接出现用户id
      const chosen = this.getChosenItemsArr();
      // 检索项 + 已选项的并集
      const result = [...arr, ...chosen.filter(item => !arr.includes(item))];
      if (arr.length > 30) {
        this.allStudentsTop30.push(...result);
      } else {
        this.allStudentsTop30.push(...result);
      }
    },
    getChosenItemsArr() {
      // 获取已被选中的人员
      const items = [];
      for (let i = 0; i < this.allStudents.length; i++) {
        if (
          this.selectedStudents.indexOf(this.allStudents[i].id) >= 0 &&
          items.indexOf(this.allStudents[i]) < 0
        ) {
          items.push(this.allStudents[i]);
        }
      }
      return items;
    },
    async getStudents() {
      const students = await userApi.searchStudents();
      this.allStudents = students;
      // this.allStudentsTop30 = this.allStudents.slice(0, 30);
    },
    async getTests(type) {
      let tests = [];
      this.tests = [];
      let selectType = "SAT_COMPOSE_PRACTICE";
      if (type) {
        selectType = type;
      } else {
        selectType = this.diagnostic_type;
      }
      if (selectType == "SAT") {
        tests = this.sessionsTestsSAT;
      } else if (selectType == "SAT_COMPOSE_PRACTICE") {
        tests = this.sessionsTestsOnlineSATPractices;
      } else if (selectType == "ACT") {
        tests = this.sessionsTestsACT;
      } else if (selectType == "TOEFL") {
        tests = this.sessionsTestsTOEFL;
      }
      this.tests = tests;
    },
    async getAssignTest() {
      await Promise.all([
        courseApi.getAllExams({
          type: "SAT"
        }),
        courseApi.getAllExams({
          type: "TOEFL"
        }),
        courseApi.getAllExams({
          type: "ACT"
        }),
        courseApi.getAllExams({
          type: "SAT_COMPOSE_PRACTICE"
        }),
        courseApi.getOnlineSATExams({
          mark: "new",
          type: "compose-practice"
        })
      ]).then(values => {
        this.sessionsTestsSAT = values[0].exams;
        this.sessionsTestsTOEFL = values[1].exams;
        this.sessionsTestsACT = values[2].exams;
        this.sessionsTestsSATPractices = values[3].exams;
        this.sessionsTestsOnlineSATPractices = values[4].exams;
      });
      this.getTests();
    },
    async setTests() {
      let users = [];
      let examId = this.test;
      let type = 0;
      if (this.selectedStudents.length > 0) {
        this.selectedStudents.forEach(student => {
          users.push({
            exam_id: examId,
            user_id: student
          });
        });
      }
      if (this.diagnostic_type === "SAT_COMPOSE_PRACTICE") {
        await Tests.sendSATMocksEmail({
          user_exams: users,
          send_mail: this.sendEmail,
          isBind: false
        });
      } else {
        await Tests.sendTestEmail({
          user_exams: users,
          send_mail: this.sendEmail,
          mail_type: this.sendContent,
          isBind: false
        });
      }
      this.selectedStudents = [];
      this.test = null;
      this.$message({
        message: "考卷開通成功！",
        type: "success"
      });
    }
  }
};
</script>

<style scoped>
.select-test {
  width: 100%;
}
::v-deep .el-tabs__nav {
  margin-left: 80px;
}
::v-deep .el-tabs__header {
  margin-bottom: 30px;
}
</style>
