<template>
  <div>
    <el-form label-width="100px">
      <el-form-item :label="$t('grading.Teacher')">
        <TeacherSelector
          :required="false"
          :value="teacher_user_id"
          @change="selectTeacher"
        />
      </el-form-item>
      <el-form-item :label="$t('grading.Class')" v-if="classes.length > 0">
        <el-select
          v-model="sessionClassIdIndex"
          placeholder="Select Classes"
          filterable
          class="select-test"
          @change="selectCourse"
        >
          <el-option
            v-for="(test, index) in classes"
            :key="test.id"
            :label="test.title"
            :value="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('grading.Students')" v-if="students.length > 0">
        <el-select
          v-model="selectedStudents"
          placeholder="Select Classes"
          filterable
          multiple
          class="select-test"
          @change="selectStudent"
        >
          <el-option
            v-for="(student, index) in students"
            :key="index"
            :label="
              student.first_name +
                ' ' +
                student.last_name +
                ' (' +
                student.email +
                ')'
            "
            :value="student.user_id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('grading.Test')" v-if="tests.length > 0">
        <el-select
          v-model="testIndex"
          placeholder="Select Test"
          filterable
          class="select-test"
          @change="selectTest"
        >
          <el-option
            v-for="(test, index) in tests"
            v-show="test.which_week != 0"
            :key="index"
            :label="'Test' + test.which_week + ' (' + test.title + ')'"
            :value="index"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        :label="$t('grading.Email Content')"
        class="keyboard"
        v-if="test > 0"
      >
        <el-radio-group
          v-model="sendContent"
          v-if="diagnostic_type === 'COMPOSE PRACTICE'"
        >
          <el-radio-button label="3">SAT線上考試鏈接</el-radio-button>
        </el-radio-group>
        <el-radio-group
          v-model="sendContent"
          v-else-if="diagnostic_type === 'TOEFL'"
        >
          <el-radio-button label="3">TOEFL線上考試鏈接</el-radio-button>
        </el-radio-group>
        <el-radio-group v-else v-model="sendContent">
          <el-radio-button label="1"
            >{{ $t("grading.PDF") }}＋{{ $t("grading.Timer") }}＋{{
              $t("grading.Grading Link")
            }}</el-radio-button
          >
          <el-radio-button label="2"
            >{{ $t("grading.Timer") }}＋{{
              $t("grading.Grading Link")
            }}</el-radio-button
          >
          <el-radio-button label="3">{{
            $t("grading.Grading Link")
          }}</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-divider></el-divider>
      <div class="text-center" style="margin-bottom: 20px">
        <el-button
          type="success"
          @click="setTests"
          :disabled="!(selectedStudents.length > 0 && test > 0)"
        >
          {{ $t("greet.setTest") }}
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import TeacherSelector from "@/components/selector/Teacher.vue";
import Tests from "@/apis/tests.js";

export default {
  components: {
    TeacherSelector
  },

  mixins: [],

  props: [],
  data() {
    return {
      teacher_user_id: null,
      sessionClassIdIndex: null,
      classes: [],
      tests: [],
      students: [],
      selectedStudents: [],
      which_week: null,
      test: null,
      diagnostic_type: "COMPOSE PRACTICE",
      sendContent: "3"
    };
  },
  computed: {},
  watch: {
    diagnostic_type(val) {
      if (val === "COMPOSE PRACTICE" || val === "TOEFL") {
        this.sendContent = "3";
      } else {
        this.sendContent = "1";
      }
    }
  },

  mounted() {},

  methods: {
    selectTeacher(val) {
      this.teacher_user_id = val.id;
      this.getGroupCourses(this.teacher_user_id);
    },
    gradingClass() {
      this.classes = [];
      this.students = {};
      this.tests = [];
      this.test = null;
      (this.diagnostic_type = "SAT"), (this.sessionClassIdIndex = null);
    },
    async getGroupCourses(teacher_uid) {
      this.gradingClass();
      const res = await Tests.getGroupCourses({
        teacher_id: teacher_uid
      });
      if (res.session_classes != undefined) {
        this.classes = res.session_classes;
      } else {
        this.$message({
          message: "該老師暫時沒有正在進行中的課程。",
          type: "warning"
        });
        this.classes = [];
      }
    },
    selectCourse(index) {
      this.students = this.classes[index].students;
      this.tests = this.classes[index].exams;
      this.testIndex = null;
      this.selectedStudents = [];
      if (this.students && this.students.length > 0) {
        this.students.forEach(student => {
          this.selectedStudents.push(student.user_id);
        });
      }
    },
    selectStudent(index) {
      // this.form.student = this.students[index].user_id;
    },
    selectTest(index) {
      this.test = this.tests[index].exam_id;
      this.which_week = this.tests[index].which_week;
      this.diagnostic_type = this.tests[
        index
      ].exam_category_title.toUpperCase();
    },

    async setTests() {
      let users = [];
      await Tests.setTeacherExams({
        session_class_id: this.classes[this.sessionClassIdIndex].id,
        which_week: this.which_week,
        exam_id: this.test,
        user_ids: this.selectedStudents,
        mail_type: this.sendContent
      });
      this.$message({
        message: "考卷郵件發送成功！",
        type: "success"
      });
    }
  }
};
</script>

<style scoped>
.select-test {
  width: 100%;
}
::v-deep .el-tabs__nav {
  margin-left: 80px;
}
::v-deep .el-tabs__header {
  margin-bottom: 30px;
}
</style>
