<template>
  <div class="container" style="padding-bottom:20px">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ $t("greet.setTest") }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading :content="$t('greet.setTest')"></Heading>
    <span>
      發送考卷給學生
    </span>
    <hr />
    <div v-if="isRoleAdmin() || isRoleTeacher()">
      <div class="title">
        <!-- <el-form label-width="100px">
          <el-form-item :label="$t('grading.Type')">
            <el-radio-group v-model="gradingType">
              <el-radio-button label="1">
                班級考卷
              </el-radio-button>
              <el-radio-button label="0">
                學生考卷
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form> -->
        <!-- 個人自主批改 -->
        <template v-if="gradingType === '0'">
          <StudentExam></StudentExam>
        </template>
        <!-- 團班批改 -->
        <template v-if="gradingType === '1'">
          <TeacherExam></TeacherExam>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import role from "@/mixins/role.js";
import Breadcrumb from "@/components/Breadcrumb";
import StudentExam from "@/views/tests/setTest/components/StudentExam.vue";
import TeacherExam from "@/views/tests/setTest/components/TeacherExam.vue";

export default {
  metaInfo() {
    return {
      title: "發送考卷 - Ivy-Way Academy"
    };
  },

  components: { StudentExam, TeacherExam, Breadcrumb },

  mixins: [role],

  props: [],
  data() {
    return {
      gradingType: "0"
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped></style>
